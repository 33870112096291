<template>
    <Layout>
        <LayoutHeader
            style="background: #fff; padding: 0;position: fixed;width: 100%;z-index: 2;"
        >
            <div class="logo">
                <img
                    src="@/assets/logo.png"
                    style="width:26px; margin-right: 10px;"
                />
                犀牛洞察
            </div>
            <Menu
                v-model:selectedKeys="navTabSelectedKey"
                theme="light"
                mode="horizontal"
                :items="navTabItems"
                :style="{ lineHeight: '64px', float: 'left' }"
                @click="navTabClick"
            >
            </Menu>
            <Dropdown
                :trigger="['click']"
                class="username"
            >
                <a
                    class="ant-dropdown-link"
                    @click.prevent
                >
                    {{ username }}
                    <DownOutlined />
                </a>
                <template #overlay>
                    <Menu @click="userMenuClick">
                        <MenuItem key="1">退出登录</MenuItem>
                    </Menu>
                </template>
            </Dropdown>
            <div class="user-info">

            </div>
        </LayoutHeader>
        <LayoutContent style="position: relative;      
        margin-top: 64px;
        width: 100%;      
        min-height: calc(100vh - 112px);                 
        ">
            <router-view />
        </LayoutContent>
        <LayoutFooter style="textAlign:center;height: 32px;">
            ©2020 北京犀牛灵动科技有限公司 京ICP备20012425号
        </LayoutFooter>
    </Layout>
</template>
  
<script setup>
import { ref, watch, onMounted } from 'vue';
import {
    Layout, LayoutHeader, LayoutContent, LayoutFooter,
    Menu, MenuItem, Dropdown
} from 'ant-design-vue';
import { useRouter, useRoute } from 'vue-router';
import { DownOutlined } from '@ant-design/icons-vue';
import { useYQMonitorStore } from "@/store/index";

const yqMonitorStore = useYQMonitorStore();
// 路由跳转
const $router = useRouter();
// 获取路由参数
const $route = useRoute();
const navTabSelectedKey = ref([]);
const username = ref("");
onMounted(() => {
    username.value = localStorage.getItem('username');
});

watch($router.currentRoute, () => {
    if ($route.path === '/addproject') {
        navTabSelectedKey.value = ['/project'];
    } else {
        navTabSelectedKey.value = [$route.path];
    }
}, { immediate: true });

const navTabItems = $route.matched[0].children.map(item => {
    if (item.meta.nav) {
        return {
            key: item.path,
            icon: item.meta.icon,
            label: item.meta.title
        }
    }
});

const menuClick = e => {
    const key = e.key;
    console.log('click', e, key);
};

const navTabClick = e => {
    $router.push(e.key);
};

const userMenuClick = e => {
    const key = e.key;
    switch (key) {
        case "1":
            // 退出登录
            yqMonitorStore.logout();
            break;

        default:
            break;
    }
};
</script>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

.logo {
    width: 200px;
    display: flex;
    float: left;
    align-items: center;
    justify-content: center;
    height: 64px;
    font-size: 16px;
    font-weight: bold;
}

.trigger {
    float: left;
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.username {
    position: relative;
    float: right;
    right: 20px;
}
</style>
  