import { defineStore } from 'pinia';
import router from '@/router/index.js';
import dayjs from 'dayjs';

// 舆情监测
export const useYQMonitorStore = defineStore({
  id: 'YQMonitor',
  state: () => ({
    dataList: [],
    reportDataList: [],
    topicData: {
      "columns": [
        { title: "话题", dataIndex: '话题' },
        { title: "数量", dataIndex: '数量' },
        {
          title: "小红书",
          dataIndex: '小红书'
        }, {
          title: "B站",
          dataIndex: 'B站'
        }, {
          title: "抖音",
          dataIndex: '抖音'
        },
      ]
    },
    projectList: [],
    totalCount: 0,
    projectKeywords: [],
    runCrawlerResult: ""
  }),
  getters: {
    suggestKeywords() {
      // 使用 computed 属性将 projectKeywords 转换成指定的格式
      return this.projectKeywords.map(keyword => {
        return { value: keyword };
      });
    }
  },
  actions: {
    // 开启爬虫
    async fetchRunCrawler() {
      const response = await fetch('/api/run_crawler', {
        method: 'GET',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // 解析 JSON 数据
      const jsonData = await response.json();
      if (jsonData["result"] === "success") {
        this.runCrawlerResult = jsonData["data"]
      }
    },


    /**
     * 异步获取登录信息
     * @param {string} username 用户名
     * @param {string} password 密码
     * @returns {Promise} 返回一个Promise对象，成功时携带登录结果，失败时携带错误信息
     */
    async fetchLogin(username, password) {
      const response = await fetch('/api/login', {
        headers: {
          'Content-Type': 'application/json', // 指定请求体的数据类型为 JSON
        },
        body: JSON.stringify({
          "username": username,
          "password": password
        }),
        method: 'POST',
      });
      if (!response.ok) {
        return false;
      }
      const jsonData = await response.json();
      if (jsonData["result"] === "success") {
        const token = jsonData["token"]
        const id = jsonData["id"]
        document.cookie = "token=" + token + "; path=/"
        document.cookie = "id=" + id + "; path=/"
        return true;
      } else {
        return false;
      }
    },

    // 退出登录
    logout() {
      document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
      document.cookie = "id=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/"
      router.push("/login")
    },
    // 搜索
    async fetchSearchDatas(page = 0, page_size = 10, platform, s_type, keywords, project_id, dateValue) {
      let start_date, end_date;
      if (dateValue != undefined) {
        start_date = dateValue.length > 0 ? dayjs(dateValue[0]).format("YYYY-MM-DD") : undefined;
        end_date = dateValue.length > 1 ? dayjs(dateValue[1]).format("YYYY-MM-DD") : undefined;
      }

      const response = await fetch('/api/search', {
        headers: {
          'Content-Type': 'application/json', // 指定请求体的数据类型为 JSON
        },
        body: JSON.stringify({
          "page": page,
          "page_size": page_size,
          "platform": platform === "0" ? null : platform,
          "s_type": s_type === "0" ? null : s_type,
          "keywords": keywords,
          "project_id": project_id,
          "start_date": start_date,
          "end_date": end_date
        }),
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      if (jsonData["code"] === 401) {
        this.logout();
        return false;
      }
      if (jsonData["result"] === "success") {
        this.dataList = jsonData["data"]
        this.totalCount = parseInt(jsonData["total_count"])
        this.projectKeywords = jsonData["project_keywords"]
        return true;
      }
    },

    // 获取负面舆情
    async fetchNegativeDatas(page = 0, page_size = 10, keywords, project_id, dateValue) {
      let start_date, end_date;
      if (dateValue != undefined) {
        start_date = dateValue.length > 0 ? dayjs(dateValue[0]).format("YYYY-MM-DD") : undefined;
        end_date = dateValue.length > 1 ? dayjs(dateValue[1]).format("YYYY-MM-DD") : undefined;
      }

      const response = await fetch('/api/search', {
        headers: {
          'Content-Type': 'application/json', // 指定请求体的数据类型为 JSON
        },
        body: JSON.stringify({
          "page": page,
          "page_size": page_size,
          "s_type": "2",
          "keywords": keywords,
          "project_id": project_id,
          "start_date": start_date,
          "end_date": end_date
        }),
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      if (jsonData["code"] === 401) {
        this.logout();
        return false;
      }
      if (jsonData["result"] === "success") {
        this.reportDataList = jsonData["data"]
        return true;
      }
    },

    // 下载
    async downloadSearchDatas(platform, s_type, keywords, project_id, dateValue) {
      let start_date, end_date;
      if (dateValue != undefined) {
        start_date = dateValue.length > 0 ? dayjs(dateValue[0]).format("YYYY-MM-DD") : undefined;
        end_date = dateValue.length > 1 ? dayjs(dateValue[1]).format("YYYY-MM-DD") : undefined;
      }

      const response = await fetch('/api/download_search_data', {
        headers: {
          'Content-Type': 'application/json', // 指定请求体的数据类型为 JSON
        },
        body: JSON.stringify({
          "platform": platform === "0" ? null : platform,
          "s_type": s_type === "0" ? null : s_type,
          "keywords": keywords,
          "project_id": project_id,
          "start_date": start_date,
          "end_date": end_date
        }),
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      // 获取响应头中的文件名
      const filename = response.headers.get('Content-Disposition').split('filename=')[1];

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename; // 使用服务器返回的文件名
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },

    // 更新用户信息
    async updateUserInfo(user_id, nickname, avatar, tag) {
      const response = await fetch('/api/update_user', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "user_id": user_id,
          "nickname": nickname,
          "avatar": avatar,
          "tag": tag
        }),
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) {
        return false;
      }
      const jsonData = await response.json();
      if (jsonData["result"] === "success") {
        return true;
      }
      return false;
    },

    // 人工更改情感判断
    async updateSType(note_id, s_type) {
      const response = await fetch('/api/update_stype', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "note_id": note_id,
          "s_type": s_type,
        }),
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) {
        return false;
      }
      const jsonData = await response.json();
      if (jsonData["result"] === "success") {
        return true;
      }
      return false;
    },

    // 获取舆情监控列表
    async getProjectList(id, name, keywords, black_keywords) {
      const response = await fetch('/api/project_list', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({

        }),
        method: 'POST',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      if (jsonData["code"] === 401) {
        this.logout();
        return false;
      }
      if (jsonData["result"] === "success") {
        this.projectList = jsonData["data"]
        return true;
      }
    },

    // 新增舆情监控
    async addProject(name, keywords, black_keywords) {
      const response = await fetch('/api/add_project', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "name": name,
          "keywords": keywords,
          "black_keywords": black_keywords,
        }),
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) {
        return false;
      }
      const jsonData = await response.json();
      if (jsonData["result"] === "success") {
        return true;
      }
      return false;
    },

    // 更新舆情监控
    async updateProject(id, name, keywords, black_keywords) {
      const response = await fetch('/api/update_project', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "id": id,
          "name": name,
          "keywords": keywords,
          "black_keywords": black_keywords,
        }),
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) {
        return false;
      }
      const jsonData = await response.json();
      if (jsonData["result"] === "success") {
        return true;
      }
      return false;
    },

    // 更改舆情监控项目状态
    async projectStatus(id, status) {
      const response = await fetch('/api/project_status', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "id": id,
          "status": status
        }),
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) {
        return false;
      }
      const jsonData = await response.json();
      if (jsonData["result"] === "success") {
        return true;
      }
      return false;
    },

    // 话题分析
    async fetchTopicData(keywords, project_id) {
      const response = await fetch('/api/topic_data', {
        headers: {
          'Content-Type': 'application/json', // 指定请求体的数据类型为 JSON
        },
        body: JSON.stringify({
          "keywords": keywords,
          "project_id": project_id
        }),
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      if (jsonData["code"] === 401) {
        this.logout();
        return false;
      }
      if (jsonData["result"] === "success") {
        this.topicData = jsonData["data"]
        return true;
      }
    },
  },
});

// 品牌监控
export const useBrandMonitorStore = defineStore({
  id: 'BrandMonitor',
  state: () => ({
    dataList: [],
    projectList: [
    ],
    timeFrames: ["全部", "24h", "3天", "一周", "一月"],
    selectTimeFrames: [true, false, false, false, false, false, false],
    mediaChannels: ["全部", "网媒", "微博", "公众号", "小红书", "报刊"],
    totalCount: 0,
  }),
  getters: {},
  actions: {
    // 获取品牌监控列表
    async getProjectList(id, name, keywords, black_keywords) {
      const response = await fetch('/api/brand_project_list', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({

        }),
        method: 'POST',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      if (jsonData["code"] === 401) {
        this.logout();
        return false;
      }
      if (jsonData["result"] === "success") {
        this.projectList = jsonData["data"]
        return true;
      }
    },

    // 获取文章列表
    async fetchArticleDatas(params) {
      const response = await fetch('/api/articles', {
        headers: {
          'Content-Type': 'application/json', // 指定请求体的数据类型为 JSON
        },
        body: JSON.stringify(params),
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      if (jsonData["code"] === 401) {
        this.logout();
        return false;
      }
      if (jsonData["result"] === "success") {
        this.dataList = jsonData["data"]
        this.totalCount = parseInt(jsonData["total_count"])
        return true;
      }
    },
  },
});
