<template>
  <Layout>
    <LayoutSider
      theme="light"
      style="        
        position: fixed;
        overflow: auto;
        height: calc(100vh);        
      "
      :trigger="null"
    >
      <Menu
        v-model:selectedKeys="projectSelectedKey"
        mode="inline"
        @click="projectMenuClick"
      >
        <template
          v-for="item in yqMonitorStore.projectList"
          :key="item.id"
        >
          <MenuItem>
          <StopTwoTone
            two-tone-color="red"
            v-if="item.status === 0"
          />
          {{ item.name }}
          </MenuItem>
        </template>
      </Menu>
    </LayoutSider>
    <LayoutContent
      style="background: #fff;margin:12px 12px 0px 212px;border-radius: 8px;padding-bottom: 20px;"
    >
      <div class="home">
        <Tabs style="padding: 0px 12px;">
          <TabPane
            key="1"
            tab="搜索"
          >
            <div class="content-container">
              <div class="search-panel">
                <Button
                  style="float: right;right: 26px"
                  type="primary"
                  :icon="h(DownloadOutlined)"
                  @click="onDownLoadClick"
                >
                  下载数据
                </Button>
                <div>
                  <Space
                    direction="vertical"
                    :size="12"
                  >
                    <Space :size="8">
                      <RangePicker
                        :presets="rangePresets"
                        v-model:value="datePickerValue"
                      />
                      <Select
                        placeholder="情绪"
                        v-model:value="sType"
                        style="width: 120px"
                        :options="sTypeOptions"
                      ></Select>
                      <Select
                        placeholder="平台"
                        v-model:value="platform"
                        style="width: 90px"
                        :options="platformOptions"
                      ></Select>

                      <Button
                        type="primary"
                        shape="round"
                        :icon="h(SearchOutlined)"
                        @click="onSearchClick"
                      >
                        搜索
                      </Button>
                    </Space>
                    <Select
                      v-model:value="keywords"
                      :allowClear="true"
                      mode="tags"
                      placeholder="输入关键词精准搜索"
                      style="max-width: 500px;min-width: 250px;"
                      :options="yqMonitorStore.suggestKeywords"
                    ></Select>
                  </Space>
                </div>
              </div>
              <List
                item-layout="vertical"
                size="large"
                :data-source="yqMonitorStore.dataList"
              >
                <template #renderItem="{ item }">
                  <ListItem
                    key="item.node_id"
                    style="position: relative;"
                  >
                    <template #actions>
                      <span>
                        <LikeOutlined style="margin-right: 8px" />
                        {{ item.liked_count }}
                      </span>
                      <span>
                        <StarOutlined style="margin-right: 8px" />
                        {{ item.collected_count }}
                      </span>
                      <span>
                        <MessageOutlined style="margin-right: 8px" />
                        {{ item.comment_count }}
                      </span>

                      <span v-if="item.s_type === 1">
                        <Tag
                          color="success"
                          style="margin-right: 8px"
                        >正面{{ item.s_score.toFixed(2) }}</Tag>
                        <Tag
                          v-if="item.s_score <= 0.9"
                          color="warning"
                          style="margin-right: 8px"
                        >需要人工判断{{ item.s_score.toFixed(2) }}</Tag>
                      </span>
                      <span v-else-if="item.s_type === 2">
                        <Tag
                          color="error"
                          style="margin-right: 8px"
                        >负面{{ item.s_score.toFixed(2) }}</Tag>
                        <Tag
                          v-if="item.s_score <= 0.9"
                          color="warning"
                          style="margin-right: 8px"
                        >需要人工判断{{ item.s_score.toFixed(2) }}</Tag>
                      </span>

                      <span v-if="item.platform === 1">
                        <Tag
                          color="cyan"
                          style="margin-right: 8px"
                        >
                          <template #icon>
                            <img
                              src="@/assets/xhs_icon.png"
                              style="height: 12px; margin-top: -2px;"
                            />
                          </template>
                        </Tag>
                      </span>
                      <span v-else-if="item.platform === 2">
                        <Tag
                          color="cyan"
                          style="margin-right: 8px"
                        >
                          <template #icon>
                            <img
                              src="@/assets/bilibli_icon.png"
                              style="height: 12px; margin-top: -2px;"
                            />
                          </template>
                        </Tag>
                      </span>
                      <span v-else-if="item.platform === 3">
                        <Tag
                          color="cyan"
                          style="margin-right: 8px"
                        >
                          <template #icon>
                            <img
                              src="@/assets/douyin_icon.png"
                              style="height: 12px; margin-top: -2px;"
                            />
                          </template>
                        </Tag>
                      </span>
                      <span v-else-if="item.platform === 4">
                        <Tag
                          color="cyan"
                          style="margin-right: 8px"
                        >
                          <template #icon>
                            <img
                              src="@/assets/zhihu_icon.png"
                              style="height: 12px; margin-top: -2px;"
                            />
                          </template>
                        </Tag>
                      </span>
                      <span v-else-if="item.platform === 5">
                        <Tag
                          color="cyan"
                          style="margin-right: 8px"
                        >
                          <template #icon>
                            <img
                              src="@/assets/weibo_icon.png"
                              style="height: 12px; margin-top: -2px;"
                            />
                          </template>
                        </Tag>
                      </span>
                    </template>

                    <ListItemMeta>
                      <template #description>
                        {{ moment.unix(item.time).format('YYYY-MM-DD HH:mm') }}
                        {{ item.ip_location }}
                      </template>
                      <template #title>
                        {{ item.nickname }}
                        <Tag
                          v-if="item.tag == 2"
                          :bordered="false"
                          color="gold"
                        >官方号</Tag>
                        <Tag
                          v-else-if="item.tag == 3"
                          :bordered="false"
                          color="volcano"
                        >营销号</Tag>
                        <Tag
                          v-else-if="item.tag == 4"
                          :bordered="false"
                          color="volcano"
                        >特别关注</Tag>
                      </template>
                      <template #avatar>
                        <Avatar :src="item.avatar" />
                      </template>
                    </ListItemMeta>
                    <Popover placement="bottomRight">
                      <template #content>
                        <Button
                          type="text"
                          size="small"
                          @click="onSTypeClick(item)"
                        >
                          修改情感判断
                        </Button>
                        <br />
                        <Button
                          type="text"
                          size="small"
                          @click="onBlackUserClick(item)"
                        >
                          拉黑账号
                        </Button>
                        <br />
                        <Button
                          type="text"
                          size="small"
                          @click="onMarkOfficialClick(item)"
                        >
                          标记为官方账号
                        </Button>
                        <br />
                        <Button
                          type="text"
                          size="small"
                          @click="onMarkMarkteingClick(item)"
                        >
                          标记为营销号
                        </Button>
                        <br />
                        <Button
                          type="text"
                          size="small"
                          @click="onMarkCommonClick(item)"
                        >
                          标记为普通号
                        </Button>
                        <Button
                          type="text"
                          size="small"
                          @click="onMarkWarningClick(item)"
                        >
                          标记为特别关注
                        </Button>

                      </template>
                      <Button
                        style="position: absolute;top: 0;right: 0;margin-top: 12px;"
                        type="text"
                        size="small"
                      >
                        <template #icon>
                          <MoreOutlined />
                        </template>
                      </Button>
                    </Popover>
                    <a
                      :href="item.link"
                      target="_blank"
                    >
                      <TypographyTitle :level="5">
                        {{ item.title }}
                      </TypographyTitle>
                      <TypographyParagraph
                        :ellipsis="{ rows: 2, expandable: false }"
                        :content="item.content"
                      >
                      </TypographyParagraph>
                      <TypographyParagraph v-if="item.summary">
                        <blockquote>AI总结：{{ item.summary }}</blockquote>
                        <!-- <pre>AI：{{ item.summary }}</pre> -->
                      </TypographyParagraph>
                    </a>
                  </ListItem>
                </template>
              </List>
              <span style="margin: 0 0 0 20px;font-weight: bold;">
                共{{ yqMonitorStore.totalCount }}条数据
              </span>
              <Pagination
                style="float: right;"
                v-model:current="currentPage"
                :pageSize="10"
                size="large"
                :total="yqMonitorStore.totalCount"
                :showSizeChanger="false"
                @change="onPageChange"
              />
              <Modal
                v-model:open="sTypeModalOpen"
                title="修改情感判断"
                @ok="onSTypeModalOK"
                cancelText="取消"
                okText="确认"
                :confirm-loading="modalConfirmLoading"
              >
                <RadioGroup
                  style="margin-top: 10px;"
                  v-model:value="sTypeRadioValue"
                  button-style="solid"
                >
                  <RadioButton :value="1">正面</RadioButton>
                  <RadioButton :value="2">负面</RadioButton>
                </RadioGroup>

              </Modal>
              <Modal
                v-model:open="commonModalOpen"
                :title="modalTitle"
                :confirm-loading="modalConfirmLoading"
                cancelText="取消"
                okText="确认"
                @ok="onModalOK"
              >
                <p>{{ modalText }}</p>
              </Modal>
            </div>
          </TabPane>
          <TabPane
            key="2"
            tab="生成舆情报告"
          >
            <div class="content-container">
              <div class="search-panel">
                <Button
                  style="float: right;right: 26px"
                  type="primary"
                  @click="onReportClick"
                >
                  查看报告
                </Button>
                <div>
                  <Space
                    direction="vertical"
                    :size="12"
                  >
                    <Space :size="8">
                      <RangePicker
                        :presets="rangePresets"
                        v-model:value="datePickerValue"
                      />
                      <Select
                        v-model:value="keywords"
                        :allowClear="true"
                        mode="tags"
                        placeholder="输入关键词精准搜索"
                        style="max-width: 500px;min-width: 250px;"
                        :options="yqMonitorStore.suggestKeywords"
                      ></Select>
                      <Button
                        type="primary"
                        shape="round"
                        :icon="h(SearchOutlined)"
                        @click="onNegativeSearchClick"
                      >
                        搜索
                      </Button>
                    </Space>
                  </Space>
                </div>
              </div>
              <Table
                style="margin-top: 20px;"
                :row-selection="{ selectedRowKeys: reportSelectedRowKeys, onChange: onReportSelectChange }"
                :columns="reportColumns"
                :data-source="yqMonitorStore.reportDataList"
                :row-key="record => record.note_id"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'total_count'">
                    {{ record.liked_count + record.comment_count
        + record.share_count + record.collected_count }}
                  </template>
                  <template v-else-if="column.dataIndex === 'title'">
                    <a
                      :href="record.link"
                      target="_blank"
                      style="color: #1890ff;"
                    >
                      {{ record.title }}
                    </a>
                  </template>
                  <template v-else-if="column.dataIndex === 'platform'">
                    <p v-if="record.platform === 1">
                      小红书
                    </p>
                    <p v-else-if="record.platform === 2">
                      b站
                    </p>
                    <p v-else-if="record.platform === 3">
                      抖音
                    </p>
                    <p v-else-if="record.platform === 4">
                      知乎
                    </p>
                  </template>
                  <template v-else-if="column.dataIndex === 'time'">
                    {{ moment.unix(record.time).format('YYYY/MM/DD HH:mm') }}
                  </template>
                </template>

              </Table>
              <Drawer
                title="舆情报告"
                size="large"
                v-model:open="reportPanelOpen"
              >
                <template #extra>
                  <Button
                    type="primary"
                    shape="round"
                    :icon="h(CopyOutlined)"
                    @click="onCopyReportClick"
                  >
                    复制
                  </Button>
                </template>
                <p v-html="reportContent">
                </p>
              </Drawer>
            </div>
          </TabPane>
        </Tabs>

      </div>
    </LayoutContent>
  </Layout>
</template>

<script setup>
import { h } from 'vue';
import {
  Button,
  List, ListItem, ListItemMeta,
  Avatar, Select, Tag, Space,
  TypographyParagraph, TypographyTitle,
  Popover, Modal,
  RadioButton, RadioGroup,
  message,
  Layout, LayoutSider, LayoutContent,
  Menu, MenuItem, Pagination,
  RangePicker, Tabs, TabPane, Drawer, Table
} from 'ant-design-vue';
import {
  StarOutlined, LikeOutlined, MessageOutlined,
  SearchOutlined, MoreOutlined, StopTwoTone, DownloadOutlined, CopyOutlined
} from '@ant-design/icons-vue';
import 'ant-design-vue/dist/reset.css';
import { useYQMonitorStore } from "@/store/index";
import { onMounted, computed, ref } from 'vue';
import moment from 'moment';
import dayjs from 'dayjs';

const sType = ref();
const platform = ref();
const keywords = ref([]);
const projectSelectedKey = ref();
const datePickerValue = ref();

const yqMonitorStore = useYQMonitorStore();

const sTypeModalOpen = ref(false);
const commonModalOpen = ref(false);
const modalTitle = ref("提示");
const modalText = ref("确认操作吗?");
const modalConfirmLoading = ref(false);
const sTypeRadioValue = ref(1);
const currentPage = ref(1);
const reportPanelOpen = ref(false);
const reportSelectedRowKeys = ref([]);
const reportContent = ref("");
var currentItem;
const reportColumns = [
  {
    title: '平台',
    dataIndex: 'platform',
    width: 100
  },
  {
    title: '发布账号',
    dataIndex: 'nickname',
    width: 150
  },
  {
    title: '最后修改时间',
    dataIndex: 'time',
    width: 160
  },
  {
    title: '标题',
    dataIndex: 'title',
    width: 250
  }, {
    title: '内容总结',
    dataIndex: 'summary',
  }, {
    title: '总互动量',
    dataIndex: 'total_count',
    key: 'total_count',
    width: 100
  }
];

const sTypeOptions = ref([
  {
    value: '0',
    label: '全部',
  },
  {
    value: '1',
    label: '正面',
  },
  {
    value: '2',
    label: '负面',
  },
  {
    value: '3',
    label: '需人工判断',
  },
]);

const platformOptions = ref([
  {
    value: "0",
    label: "全部"
  },
  {
    value: "1",
    label: "小红书"
  },
  {
    value: "2",
    label: "b站",
    disabled: false
  },
  {
    value: "3",
    label: "抖音",
    disabled: false
  },
  {
    value: "4",
    label: "知乎",
    disabled: false
  }
]);

var markUserTag = 0;

onMounted(async () => {
  var result = await yqMonitorStore.getProjectList();
  if (result) {
    projectSelectedKey.value = [yqMonitorStore.projectList[0].id];
    await yqMonitorStore.fetchSearchDatas(undefined, undefined, undefined, undefined, undefined, projectSelectedKey.value[0], datePickerValue.value);
  }
});

// const reportContent = computed(() => {
//   note_id = reportSelectedRowKeys.value
//   for (const item in yqMonitorStore.reportDataList) {
//     if (item.note_id == note_id) {
//       return item.content;
//     }
//   }
// });

const rangePresets = ref([
  { label: '过去7天', value: [dayjs().add(-7, 'd'), dayjs()] },
  { label: '过去14天', value: [dayjs().add(-14, 'd'), dayjs()] },
  { label: '过去30天', value: [dayjs().add(-30, 'd'), dayjs()] },
  { label: '过去90天', value: [dayjs().add(-90, 'd'), dayjs()] },
]);

const onPageChange = async (page) => {
  document.documentElement.scrollTop = 0;
  await yqMonitorStore.fetchSearchDatas(page - 1, undefined, platform.value, sType.value, keywords.value, projectSelectedKey.value[0], datePickerValue.value);
}

const onSearchClick = async () => {
  await yqMonitorStore.fetchSearchDatas(undefined, undefined, platform.value, sType.value, keywords.value, projectSelectedKey.value[0], datePickerValue.value);
};

const onNegativeSearchClick = async () => {
  await yqMonitorStore.fetchNegativeDatas(undefined, undefined, keywords.value, projectSelectedKey.value[0], datePickerValue.value);
};


const projectMenuClick = async (item) => {
  document.documentElement.scrollTop = 0;
  currentPage.value = 1;
  datePickerValue.value = [];
  keywords.value = [];
  await yqMonitorStore.fetchSearchDatas(undefined, undefined, platform.value, sType.value, keywords.value, item.key, datePickerValue.value);
  await yqMonitorStore.fetchNegativeDatas(undefined, undefined, keywords.value, item.key, datePickerValue.value);
};

const onSTypeClick = async (item) => {
  currentItem = item;
  sTypeRadioValue.value = item.s_type;
  sTypeModalOpen.value = true;
}

const onBlackUserClick = async (item) => {
  currentItem = item;
  markUserTag = 1;
  modalTitle.value = "拉黑账号";
  modalText.value = `拉黑【${item.nickname}】后,此账号的所有内容不再抓取和展示，确定操作吗？`;
  commonModalOpen.value = true;
}

const onMarkOfficialClick = async (item) => {
  markUserTag = 2;
  currentItem = item;
  modalTitle.value = "标记为官方账号";
  modalText.value = `标记【${item.nickname}】为官方账号，确定操作吗？`;
  commonModalOpen.value = true;
}

const onMarkMarkteingClick = async (item) => {
  markUserTag = 3;
  currentItem = item;
  modalTitle.value = "标记为营销账号";
  modalText.value = `标记【${item.nickname}】为营销账号，确定操作吗？`;
  commonModalOpen.value = true;
}

const onMarkWarningClick = async (item) => {
  markUserTag = 4;
  currentItem = item;
  modalTitle.value = "标记为特别关注";
  modalText.value = `标记【${item.nickname}】为标记为特别关注账号，确定操作吗？`;
  commonModalOpen.value = true;
}

const onMarkCommonClick = async (item) => {
  markUserTag = 0;
  currentItem = item;
  modalTitle.value = "标记为普通账号";
  modalText.value = `标记【${item.nickname}】为普通账号，确定操作吗？`;
  commonModalOpen.value = true;
}

const onSTypeModalOK = async () => {
  modalConfirmLoading.value = true;

  const result = await yqMonitorStore.updateSType(currentItem.note_id, sTypeRadioValue.value);
  modalConfirmLoading.value = false;
  if (result) {
    sTypeModalOpen.value = false;
    currentItem.s_type = sTypeRadioValue.value;
    message.success('操作成功！');
  } else {
    message.error('操作失败，请重试！');
  }
}

const onModalOK = async () => {
  modalConfirmLoading.value = true;
  const result = await yqMonitorStore.updateUserInfo(currentItem.user_id, currentItem.nickname, currentItem.avatar, markUserTag);
  modalConfirmLoading.value = false;
  if (result) {
    commonModalOpen.value = false;
    currentItem.tag = markUserTag;
    message.success('操作成功！');
  } else {
    message.error('操作失败，请重试！');
  }
}


const onDownLoadClick = async () => {
  await yqMonitorStore.downloadSearchDatas(platform.value, sType.value, keywords.value, projectSelectedKey.value[0], datePickerValue.value);
};

const onReportClick = () => {
  reportPanelOpen.value = true;
};

const onCopyReportClick = () => {
  navigator.clipboard.writeText(reportContent.value.replaceAll("<br>", "\n"))
    .then(() => {
      message.success('复制成功！');
    })
    .catch(err => {
      message.error('复制失败！');
    });
};
const onReportSelectChange = (selectedRowKeys, selectedRows) => {
  console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows[0].platform);
  reportSelectedRowKeys.value = selectedRowKeys;

  const xhs_count = selectedRows.filter(item => item.platform === 1).length;
  const blibli_count = selectedRows.filter(item => item.platform === 2).length;
  const douyin_count = selectedRows.filter(item => item.platform === 3).length;
  const zhihu_count = selectedRows.filter(item => item.platform === 4).length;
  reportContent.value = "### 负面舆情：<br>#### 新增负面舆情共" + selectedRows.length + "条";

  if (xhs_count > 0) {
    reportContent.value += "<br>- 小红书:" + xhs_count + "条";
  }
  if (blibli_count > 0) {
    reportContent.value += "<br>- 哔哩哔哩:" + blibli_count + "条";
  }
  if (douyin_count > 0) {
    reportContent.value += "<br>- 抖音:" + douyin_count + "条";
  }
  if (zhihu_count > 0) {
    reportContent.value += "<br>- 知乎:" + zhihu_count + "条";
  }

  const sortedArray = selectedRows.slice();
  sortedArray.sort((a, b) => {
    const sumA = a.liked_count + a.comment_count + a.share_count + a.collected_count;
    const sumB = b.liked_count + b.comment_count + b.share_count + b.collected_count;

    // 根据指定属性的总和进行升序排序
    return sumB - sumA;
  });
  reportContent.value += "<br>| 平台 | 账号 | 最后修改时间 | 标题 | 内容总结 | 总互动量 |";
  reportContent.value += "<br>| ---- | ---- | ------------ | ---- | -------- | -------- |";
  for (const item of sortedArray) {
    reportContent.value += "<br>|";
    switch (item.platform) {
      case 1:
        reportContent.value += "小红书";
        break;
      case 2:
        reportContent.value += "哔哩哔哩";
        break;
      case 3:
        reportContent.value += "抖音";
        break;
      case 4:
        reportContent.value += "知乎";
        break;
    }

    reportContent.value += "|" + item.nickname + "|" + moment.unix(item.time).format('YYYY/MM/DD HH:mm') + "|" + item.title + "|" + (item.summary || "") + "|" + (item.liked_count + item.comment_count
      + item.share_count + item.collected_count) + "|";
  }
};

</script>
<style>
.home {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; */
}

.home .content-container {
  width: 100%;
  max-width: 1200px;
}

.home a {
  color: black;
}

.search-panel {
  margin-top: 20px;
  text-align: center;
}
</style>