import { createRouter, createWebHistory } from 'vue-router'
import MonitorView from '@/views/MonitorView.vue'
import LoginView from '@/views/LoginView.vue';
import HomeView from '@/views/HomeView.vue';
import { h } from 'vue';
import {
  RadarChartOutlined,
  UnorderedListOutlined,
  SearchOutlined,
  NumberOutlined,
  AlertOutlined
} from '@ant-design/icons-vue';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      keepAlive: true,
      requiresAuth: false,
      nav: false
    },
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { keepAlive: true, requiresAuth: true, nav: false },
    children: [
      {
        path: '/',
        name: 'monitor',
        component: MonitorView,
        meta: {
          keepAlive: true,
          title: '数据监控',
          icon: h(RadarChartOutlined),
          nav: true
        }
      },
      {
        path: '/setting',
        name: 'setting',
        component: function () {
          return import('../views/SettingView.vue')
        },
        meta: {
          keepAlive: false,
          title: '设置',
          nav: false
        }
      },
      {
        path: '/project',
        name: 'project',
        component: function () {
          return import('../views/ProjectView.vue')
        },
        meta: {
          keepAlive: true,
          title: '监测管理',
          icon: h(UnorderedListOutlined),
          nav: true
        },
      },
      {
        path: '/addproject',
        name: 'addproject',
        component: function () {
          return import('../views/AddProjectView.vue')
        },
        meta: {
          keepAlive: false,
          title: '添加监测'
        },
      },
      {
        path: '/fullsearch',
        name: 'fullsearch',
        component: function () {
          return import('../views/FullSearchView.vue')
        },
        meta: {
          keepAlive: false,
          title: '全文搜索',
          icon: h(SearchOutlined),
          nav: false
        },
      },
      {
        path: '/topic',
        name: 'topic',
        component: function () {
          return import('../views/TopicView.vue')
        },
        meta: {
          keepAlive: true,
          title: '话题分析',
          icon: h(NumberOutlined),
          nav: true
        },
      },
      {
        path: '/brand-monitor',
        name: 'brand-monitor',
        component: function () {
          return import('../views/BrandMonitorView.vue')
        },
        meta: {
          keepAlive: true,
          title: '品牌监控',
          icon: h(AlertOutlined),
          nav: true
        },
      }]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 在这里进行权限验证或其他操作  
  if (to.meta.requiresAuth) {
    const token = document.cookie.includes('token');
    if (!token) {
      next('/login'); // 重定向到登录页
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router
