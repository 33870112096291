<template>
    <div class="login container">
        <div class="title">犀牛洞察</div>
        <Form
            class="login-form"
            layout="vertical"
            :model="formState"
            @finish="onFinish"
            @finishFailed="onFinishFailed"
        >
            <FormItem
                label="账号"
                name="username"
                :rules="[{ required: true, message: '请输入你的手机号!' }]"
            >
                <Input
                    size="large"
                    v-model:value="formState.username"
                >
                </Input>
            </FormItem>
            <FormItem
                label="密码"
                name="password"
                :rules="[{ required: true, message: '请输入你的密码!' }]"
            >
                <InputPassword
                    size="large"
                    v-model:value="formState.password"
                >
                </InputPassword>
            </FormItem>
            <FormItem>
                <Button
                    style="width: 100%;"
                    :disabled="disabled"
                    type="primary"
                    html-type="submit"
                    size="large"
                >
                    登录
                </Button>
            </FormItem>
        </Form>
    </div>
</template>
  
<script setup>
import { reactive, computed } from 'vue';
import {
    Button, Form, FormItem, message, Input, InputPassword
} from 'ant-design-vue';
import { useYQMonitorStore } from "@/store/index";
import { useRouter, useRoute } from 'vue-router';

const $router = useRouter();
const yqMonitorStore = useYQMonitorStore();
const formState = reactive({
    username: '',
    password: '',
});
const onFinish = async (values) => {
    var result = await yqMonitorStore.fetchLogin(values.username, values.password);
    if (result) {
        localStorage.setItem('username', values.username);
        $router.push("/");
    } else {
        message.success('账号或密码错误，请重试！');
    }
};

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};
const disabled = computed(() => {
    return !(formState.username && formState.password);
});

</script>
  
<style  scoped>
.login.container {
    width: 100%;
    min-height: 100%;
    background: #f0f2f5 url(../assets/login.background.svg) no-repeat 50%;
    background-size: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login.container .title {
    font-size: 33px;
    color: rgba(0, 0, 0, .85);
    font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-weight: 600;
    position: relative;
    margin-bottom: 50px;
}

.login-form {
    width: 340px;
}
</style>